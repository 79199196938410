var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.deposit_invoice,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",
                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.amount",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.amount) +
                        " " +
                        _vm._s(item.crypto.title)
                    ),
                  ]
                },
              },
              {
                key: "item.nickname",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.history ? item.history.user.nickname : "")
                    ),
                  ]
                },
              },
              {
                key: "item.userid",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.history ? item.history.user.id : "")
                    ),
                  ]
                },
              },
              {
                key: "item.created_at",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(Date.parse(item.created_at)).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.deposit_wallet",
                fn: function ({ item }) {
                  return [
                    _c("WalletView", {
                      attrs: { wallet: item.deposit_wallet.wallet },
                    }),
                  ]
                },
              },
              {
                key: "item.deposit_wallet_network",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " + _vm._s(item.deposit_wallet.crypto_network.title)
                    ),
                  ]
                },
              },
              {
                key: "item.check",
                fn: function ({ item }) {
                  return [
                    item.check
                      ? _c("a", { attrs: { href: item.check } }, [
                          _vm._v(" ссылка "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    !(
                      item.status === "Успех" ||
                      item.status === "Отмена" ||
                      item.status === "Неудача"
                    )
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "blue",
                                attrs: { dense: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.accept(item)
                                  },
                                },
                              },
                              [_vm._v("Принять")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "red",
                                attrs: { dense: "", text: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancel(item)
                                  },
                                },
                              },
                              [_vm._v("Отклонить")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Депозиты - Счета")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }