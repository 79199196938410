<template>
  <div class="ma-3">

    <Snackbar ref="snackbar"></Snackbar>

    <div class="Withdraws"><h3>Депозиты - Счета</h3></div>

    <div>
      <v-data-table
          :items="deposit_invoice"
          :headers="headers"
          class="elevation-1"
          sort-by="id"
          sort-desc

          :footer-props="{
                showFirstLastPage: true,
                showCurrentPage:true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text':'Показать на странице',
                'items-per-page-all-text':'Все',
                'items-per-page-options':[  50,  100,  200,  -1]
          }"
      >



        <template v-slot:item.amount="{ item }"> {{ item.amount }} {{    item.crypto.title }}</template>


        <template v-slot:item.nickname="{ item }"> {{ item.history ? item.history.user.nickname : "" }}</template>
        <template v-slot:item.userid="{ item }"> {{ item.history ? item.history.user.id : "" }}</template>
        <template v-slot:item.created_at="{ item }"> {{ new Date(Date.parse(item.created_at)).toLocaleString() }}  </template>

        <template v-slot:item.deposit_wallet="{ item }">  <WalletView  :wallet="item.deposit_wallet.wallet"  ></WalletView>  </template>

        <template v-slot:item.deposit_wallet_network="{ item }"> {{ item.deposit_wallet.crypto_network.title }}</template>

        <template v-slot:item.check="{ item }"> <a v-if="item.check" :href="item.check" > ссылка </a> </template>


        <template v-slot:item.actions="{ item }">
          <div v-if="!(item.status==='Успех'  || item.status==='Отмена' || item.status==='Неудача'  )">
          <v-btn class="blue" dense text @click="accept(item)">Принять</v-btn>
          <v-btn class="red" dense text @click="cancel(item)">Отклонить</v-btn>
    </div>
        </template>

      </v-data-table>
    </div>
  </div>
</template>

<script>

import gql from "graphql-tag";

import Snackbar from "../components/controls/Snackbar.vue";
import WalletView from "../components/controls/WalletView.vue";
import {fetcher, getHeaders} from "../store";


export default {
  name: 'ExchangeDepositInvoice',

  components: {WalletView, Snackbar},

  apollo: {
    $subscribe: {
      deposit_invoice: {
        query: gql`

  subscription GetUsers {
  deposits {
    actual_amount
    proof
    created_at

    id
    status
    updated_at
    wallet_id
     cryptocurrency {
      title
    }

    deposit_wallet {
      wallet
      cryptonetwork {
        title
      }
    }






  }
}

        `,
        result({data}) {
          this.deposit_invoice = data.deposit_invoice;
        },
      },
    },
  },


  data: () => ({

    deposit_invoice: [],

    headers: [
      {value: "id", text: "ИД"},
      {value: "status", text: "Статус"},
      {value: "amount", text: "Сумма"},
      {value: "deposit_wallet", text: "Кошелек"},
      {value: "deposit_wallet_network", text: "Сеть"},
      {value: "hash", text: "Хэш"},
      {value: "check", text: "Чек"},
        //{value: "created_at", text: "Дата создания"},


      {value: "updated_at", text: "Дата обновления"},
        //{value: "wallet_id", text: "Идентификатор кошелька"},


      {value: "actions", text: "Действия"},
    ],
  }),

  methods: {


    async accept(tag) {

      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        //body: JSON.stringify({amount: value})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/deposit_invoice/success/${tag.id}`

      await fetcher(this.dosnackbar, url, requestOptions)
    },

    dosnackbar(text) {
      this.$refs.snackbar.showit(text)
    },


    async cancel( value  ) {


      let headers = await getHeaders()
      headers['Content-Type'] = 'application/json'

      const requestOptions = {
        method: 'PATCH',
        headers: headers,
        //body: JSON.stringify({error_text : text})
      };

      let url = `${process.env.VUE_APP_EXCHANGE_API}/deposit_invoice/cancel/${value.id}`

      await fetcher(this.dosnackbar, url, requestOptions)
    },

    getConvValue(item, currency, old) {

      let s = `balance_${currency}_${old ? "old" : "now"}`

      let n = item[s]

      if (!(s in item))
        n = item[s.toLowerCase()]

      return n
    },

    getConvValueFrom(item) {

      //const regex = /balance_(.*?)_old/;
      //const match = inputString.match(regex);
      //return match ? match[1] : null;

      //balance_ETH_old

      const s = `balance_${item.convert_from}_old`

      let n = item[s]

      if (!(s in item))
        n = item[s.toLowerCase()]

      return n
    },

    getConvValueTo(item) {
      const s = `balance_${item.convert_to}_now`

      let n = item[s]

      if (!(s in item))
        n = item[s.toLowerCase()]


      //balance_ETH_old
      return n

    },


    amount_usdt(item) {
      return Math.abs(item.balance_usdt_old - item.balance_usdt_now)
    },

    amount_btc(item) {
      return Math.abs(item.balance_btc_old - item.balance_btc_now)
    },


    money_from2(item) {
      return Math.abs(item.balance_from_before - item.balance_from_after) + " " + item.crypto.title
    },

    money_to2(item) {
      return Math.abs(item.balance_to_before - item.balance_to_after) + " " + item.cryptoByCurrencyToId.title
    },

    money_from(item) {
      if (item.convert_to === "USDT")   //from btc
        return this.amount_btc(item) + " BTC"
      else
        return this.amount_usdt(item) + " USDT"
    },

    money_to(item) {
      if (item.convert_to === "USDT") //from usdt
        return this.amount_usdt(item) + " USDT"
      else
        return this.amount_btc(item) + " BTC"
    },

    toFixed2(num) {
      return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
    },

  },

}
</script>
